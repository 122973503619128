
import NotificationCard from '@/modules/notification/components/notification-card.vue';
import { mapActions, mapGetters } from 'vuex';
import i18n from "@/vueI18n";

export default {
  name: 'app-home-page',
  data(){
    return{
      size:4,
      rowsToBeDisplayed: [],

    }
  },

  components: {
    [NotificationCard.name]: NotificationCard,
  },

  

  computed: {
    lang(){
        // debugger    
        return i18n.locale
    },
    ...mapGetters({
        isMobile: 'layout/isMobile',
        currentUser:'auth/currentUser',
        firstBatch:'notification/firstBatch',
        secondBatch: 'notification/secondBatch',
        recordAfterLast: 'notification/recordAfterLast',
        loading: 'notification/loading'
    }),
    notificationsList(){
      return this.notifications.slice(0, this.size)
    },
    
  },
watch:{
  lang(){
      document.title =
          this.i18n('app.title') +
          ' | ' +
          this.i18n('entities.notification.title') 
  },
  // notifications(newval,oldval){
  //   console.log('newval',newval);
  //   console.log('oldval',oldval);
  // },
  async firstBatch(newval){
      if(newval.length > 0){
        this.setLoadMoreLast()
        await this.setDisableNext({userId: this.currentUser.id})
        newval.forEach(record =>{
         var foundIndex =  this.rowsToBeDisplayed.findIndex((notif) => notif.id == record.id);
         if(foundIndex == -1) {
           this.rowsToBeDisplayed.push(record)
         }
        })
        // this.rowsToBeDisplayed.sort((a, b) =>{
        //     var keyA = new Date(a.createdAt.seconds),
        //         keyB = new Date(b.createdAt.seconds);
        //     if (keyA < keyB) return 1;
        //     if (keyA > keyB) return -1;
        //     return 0;
        //   })
      }
    },
    async secondBatch(oldval,newval){
        await this.setDisableNext({userId: this.currentUser.id})
      if(newval.length > 0){
        
        newval.forEach(record =>{
         var foundIndex =  this.rowsToBeDisplayed.findIndex((notif) => notif.id == record.id);
         if(foundIndex == -1) {
           this.rowsToBeDisplayed.push(record)
         }
        })
        // this.rowsToBeDisplayed.push(...newval)
        // console.log('this.rowsToBeDisplayed',this.rowsToBeDisplayed);
      }
    },
    rowsToBeDisplayed(newval){
      if(newval.length > 0){
      //   this.setLoadMoreLast()
      //   this.setDisableNext({userId: this.currentUser.id})
      }      
    }
},
  methods: {
    i18n(key, args) {
      return this.$t(key, args);
    },
    scrollHandler(scroll){
      if(scroll.position == 0 )  {
        this.setHeaderBg('bg-accent')
      }
      else{
        // this.setHeaderBg('bg-shadow animate__animated animate__fadeInDown')
        this.setHeaderBg('bg-shadow')
      }
    },
    ...mapActions({
        setHeaderBg:'layout/setHeaderBg',
        collapseMenu: 'layout/collapseMenu',
        notificationInit:'notification/notificationInit',
        notificationCreateAndSend:'notification/notificationCreateAndSend',
        doFetchNotifications: 'notification/doFetchNotifications',
        resetCursor: 'notification/resetCursor',
        setLoadMoreLast: 'notification/setLoadMoreLast',
        doFetch: 'notification/doFetch',
        doUpdate: 'notification/doUpdate',
        setDisableNext: 'notification/setDisableNext'
    }),
    onLoad (index, done) {
          setTimeout(() => {
            if(this.recordAfterLast.length > 0){
              this.doFetchMore()
            }          
            done()
        }, 2000)      
    },
    doFetchMore(){
      this.doFetch({userId: this.currentUser.id, limit: 10})
      // this.doFetchNotifications({userId: this.currentUser.id, limit: 2})
    }
    
  },
  async created() {    
    document.title =
              this.i18n('app.title') +
              ' | ' +
              this.i18n('entities.notification.title')   
    await this.resetCursor()
    await this.doFetchNotifications({userId: this.currentUser.id, limit: 10})
  },
};
