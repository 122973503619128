import { render, staticRenderFns } from "./notification-list-page.vue?vue&type=template&id=46a89242&scoped=true&"
import script from "./notification-list-page.vue?vue&type=script&lang=js&"
export * from "./notification-list-page.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "46a89242",
  null
  
)

export default component.exports
import {QScrollObserver,QInfiniteScroll,QInnerLoading,QImg,QSpinnerIos,QSpinnerDots} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QScrollObserver,QInfiniteScroll,QInnerLoading,QImg,QSpinnerIos,QSpinnerDots})
