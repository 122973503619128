
import i18n from '@/vueI18n'
import moment from 'moment'
import { mapActions,mapGetters } from 'vuex';

export default {
  name: 'app-notification-card',
  props:['notification'],
  data() {
    return {
    };
  },
  computed: {
    lang(){
      return i18n.locale
    },
    isRTL() {
      // return getLanguageCode() == 'ar'
      // return localStorage.getItem('language') == 'ar'
      return i18n.locale == 'ar'
    },
    date(){
      moment.locale(this.lang);
      return moment(this.notification.createdAt.seconds * 1000).fromNow()        
    },
    ...mapGetters({
      currentUser:'auth/currentUser',
      is_screen_xs: 'layout/is_screen_xs'
    })
  },
  methods:{
    ...mapActions({
      doUpdate:'notification/doUpdate'
    }),
    async goToNotification(){
      
      if(this.notification.type == "prescriptionComment"){
        this.$router.push(`/prescriptions/${this.notification.postId}`)
      }
      if(this.notification.type == "offer"){
        this.$router.push(`/offers/${this.notification.postId}`)
      }
      if(this.notification.type == "propertyAccepted" || this.notification.type == "propertyPending" || this.notification.type == "propertyDestroyed"){
        this.$router.push(`/facilities/${this.notification.postId}`)
      }
      if(this.notification.type == "jobAccepted" || this.notification.type == "jobPending" || this.notification.type == "jobDestroyed"){
        this.$router.push(`/job-opportunities/${this.notification.postId}`)
      }
      if(this.notification.type == "prescriptionAccepted" || this.notification.type == "prescriptionPending" || this.notification.type == "prescriptionDestroyed"){
        this.$router.push(`/prescriptions/${this.notification.postId}`)
      }
    },
  },
      created(){
        if(this.notification.status == "unread"){
            const payload = {
              type:this.notification.type,
              status:'read',
              createdAt: new Date(this.notification.createdAt.seconds * 1000),
              postId: this.notification.postId,
              createdBy: this.notification.createdBy,
              title:this.notification.title,
              description: this.notification.description,
              new: this.notification.new
            }
          this.doUpdate({userId:this.currentUser.id,id:this.notification.id,values:payload})
          }
      }
};
